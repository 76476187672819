<template>
    <div id="categories">
        <div class="row">
            <div class="title mb-3">Categories</div>
        </div>
        <div class="row">
            <span v-for="(category, index) in categories" :key="index" class="category d-flex">
                <router-link :to="getLink(category)">
                    <span class="category-title p-1">{{ category.name }}</span>
                    <span class="category-number p-1">5</span>
                </router-link>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "categories",
    props: ["categories"],
    methods: {
        getLink(category) {
            return `/blog/${category.link.split("https://blog.willyou.net/category/")[1].replace(/\/$/, "")}`;
        },
    },
};
</script>

<style lang="scss" scoped>
#categories {
    .title {
        font-family: "Noto Serif SC", serif;
        font-size: 24px;
        font-weight: 200;
        color: rgba(0, 0, 0, 0.87);
    }
    .category {
        margin: 0 12px 12px 0;
        padding-right: 14px;
        padding-left: 14px;
        border: solid 1px #767d98;
        .category-title {
            font-family: "Noto Sans KR", sans-serif;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: rgba(0, 0, 0, 0.8);
        }
        .category-title::after {
            content: "";
            border-right: 1px solid #767d98;
            margin: 0 0 0 0.5rem;
        }
        .category-number {
            font-family: "Noto Sans KR", sans-serif;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: #ff0a53;
        }
    }
}
</style>
