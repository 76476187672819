<template>
    <div id="blog-article">
        <div v-if="isLoading && articleNeedsRefresh && !allIsLoaded" class="loading-wrapper">
            <loading></loading>
        </div>
        <div v-if="!isLoading && currentArticle && !articleNeedsRefresh && allIsLoaded">
            <div class="container">
                <paging-navigator class="bradcrumbs" :links="pagingLinks" :no-slashes="true"></paging-navigator>
                <category-hero
                    v-if="isCategoryArticle"
                    :categories="articlesCategories"
                    :articles-count="getArticlesLength"
                    :title="getHeroTitle"
                    :description="getHeroDescription"
                    :is-category-article="isCategoryArticle"
                    hierarchy="subcategory"></category-hero>
                <div class="row text-center" v-if="articlesToShow && articlesToShow.length && isGiaArticle">
                    <h2 class="title article-title col-12 low-size">Check a GIA Report and Certificates</h2>
                </div>
                <div class="row justify-content-center search-cert-num flex-column" v-if="articlesToShow && articlesToShow.length && isGiaArticle">
                    <div class="justify-content-center padding-30">
                        <search-cert-number></search-cert-number>
                    </div>
                </div>
                <div class="row">
                    <component :is="isMainTitle" class="title article-title mb-2" v-html="currentArticle.title.rendered" />
                </div>
                <div class="row">
                    <div class="sub-title text-uppercase">
                        by <span class="highlighted">{{ blogAuthor }}</span>
                        <!-- {{ getPostDate(currentArticle.date) }} -->
                        <!-- * {{blogComments.length}} comments -->
                        <span v-if="blogTags"> * in </span>
                        <span class="highlighted" :key="index" v-for="(tag, index) in blogTags"> <span v-if="index > 0">, </span>{{ getTag(tag) }} </span>
                        <!-- <span>{{currentArticle.tags[0]}}</span> -->
                    </div>
                </div>

                <div class="article-wrapper">
                    <div id="rendered-content" v-html="getArticleHtml"></div>
                    <!-- <div id="willyou-cta"></div> -->
                    <topic-list :topics="[]"></topic-list>
                </div>

                <div class="row" v-if="articlesToShow && articlesToShow.length && isGiaArticle">
                    <div class="padding-30 full-width">
                        <common-questions :questions="questions"></common-questions>
                    </div>
                </div>
                <div class="row" v-if="articlesToShow && articlesToShow.length">
                    <div class="padding-30 full-width">
                        <latest-posts class="popular-posts" v-if="$route.path === '/blog/jewelry-events-and-awards'" />
                        <carousel-posts :posts="articlesToShow" :users="usersToShow" class="popular-posts"></carousel-posts>
                    </div>
                </div>
                <script v-html="getJsonLd" v-if="getJsonLd" type="application/ld+json"></script>
            </div>
        </div>
        <successfully-loaded-indicator></successfully-loaded-indicator>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Loading from "../../../components/Loading.vue";
import { convertHexToHTML, htmlDecode } from "../../../helpers";
import Banner from "./components/Banner.vue";
import Categories from "./components/Categories.vue";
import KeepInTouch from "./components/KeepInTouch.vue";
import PopularPosts from "./components/PopularPosts.vue";
import SidebarPosts from "./components/SidebarPosts.vue";
import TopicList from "./components/TopicList.vue";

import chunk from "lodash/chunk";
import shuffle from "lodash/shuffle";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import image from "../../../../assets/images/blog/cta-ring.png";
import LatestPosts from "../../../components/LatestPosts";
import PagingNavigatorVue from "../../../components/PagingNavigator.vue";
import SocialNetworks from "../../../components/SocialNetworks.vue";
import SuccessfullyLoadedVue from "../../../components/SuccessfullyLoaded.vue";
import SearchCertificateNumber from "../../gia/components/SearchCertificateNumber";
import CommonQuestions from "../../home/sections/CommonQuestions";
import HeroVue from "../loby/components/Hero.vue";
import CategoryHeroMixinVue from "../mixins/CategoryHeroMixin.vue";
import DiamondsNearMe from "./DiamondsNearMe";
import CarouselPosts from "./components/CarouselPosts.vue";
import QuestionsAnswers from "./components/mixins/GiaQuestionsAnswers";
export default {
    name: "blog-article",
    props: ["permalink"],
    mixins: [CategoryHeroMixinVue, QuestionsAnswers],
    components: {
        categories: Categories,
        "sidebar-posts": SidebarPosts,
        "popular-posts": PopularPosts,
        "keep-in-touch": KeepInTouch,
        "common-questions": CommonQuestions,
        "search-cert-number": SearchCertificateNumber,
        banner: Banner,
        "topic-list": TopicList,
        loading: Loading,
        "paging-navigator": PagingNavigatorVue,
        "successfully-loaded-indicator": SuccessfullyLoadedVue,
        "category-hero": HeroVue,
        SocialNetworks,
        LatestPosts,
        "carousel-posts": CarouselPosts,
    },
    data() {
        return {
            image,
            articleNeedsRefresh: true,
            loadedSoFar: {
                currentArticle: false,
                categories: false,
                tags: false,
                comments: false,
                author: false,
                loadedSoFar: false,
            },
            blogComments: [],
            blogTags: null,
            blogCategories: [],
            blogAuthor: "",
        };
    },
    mounted() {
        if (this.articles.length === 0 || this.articlesCategories) {
            let perPage = this.isCategoryArticle ? 100 : 18;
            let payload = { ...this.routeProps, perPage };
            this.getArticles({ payload: payload, router: this.$router });
        }
        this.getArticle({ permalink: this.getPermalink ? this.getPermalink : "", router: this.$router });
        this.blogUsers.length === 0 ? this.getUsers() : null;
        this.verifyPath();
    },
    updated() {
        this.verifyPath();
    },
    watch: {
        allIsLoaded(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    if (this.isInnerHashLink(window.location.hash)) {
                        setTimeout(() => this.scrollToTarget(window.location.hash), 2000);
                    }
                    let key = "";
                    let article = document.getElementById("rendered-content");
                    if (!article) return;
                    let anchors = article.getElementsByTagName("a");
                    for (key in anchors) {
                        typeof anchors[key] === "object"
                            ? anchors[key].addEventListener("click", (e) => {
                                  e.preventDefault();
                                  let path = e.target.getAttribute("href");
                                  if (path.includes("http")) window.location.href = path;

                                  if (this.isInnerHashLink(path)) {
                                      if (window.location.hash !== path) {
                                          this.$router.push({
                                              path: `${window.location.pathname}${path}`,
                                          });
                                      }
                                      this.scrollToTarget(path);
                                  }
                              })
                            : null;
                    }

                    setTimeout(() => {
                        const el = document.getElementById("willyou-cta");

                        if (el) {
                            el.innerHTML = `
                            <a href="https://www.willyou.com/jewelerai" target="_blank" id="call-to-action-wrapper-id">
                            <div class="call-to-action-wrapper">
                                <h3 class="call-to-action-h3">
                                    Stop Searching, <br />
                                    Start Designing!
                                </h3>
                                <span class="call-to-action-text"
                                    >Create the perfect engagement ring instantly <br />
                                    with Jeweler AI – it’s fast, smart, and stress-free.</span
                                >
                                <button class="rnd-primary-btn">TRY NOW</button>
                            </div>
                            <img src="${image}" alt="ring background">
                            <a href="/terms-of-use" target="_blank" class="ad-link"> Ad </a>
                        </a>`;
                        }
                    }, 1000);
                });
            }
        },
        $route(to, from) {
            if (to.hash.length > 0) {
                // this.scrollToTarget(to);
            } else {
                this.loadedSoFar.category = false;
                this.loadedSoFar.tags = false;
                this.loadedSoFar.author = false;
                this.loadedSoFar.currentArticle = false;
                if (this.checkCategoryOrArticle() === "article") {
                    this.articleNeedsRefresh = true;
                    this.getArticle({
                        permalink: this.getPermalink,
                        router: this.$router,
                    });
                    this.currentArticleSurroundings(this.currentArticle);
                    let perPage = this.isCategoryArticle ? 100 : 18;
                    let payload = { ...this.routeProps, perPage };
                    this.getArticles({ payload: payload, router: this.$router });
                    window.scrollTo(0, 0);
                }
            }
        },
        currentArticle(newVal, oldVal) {
            typeof newVal !== "undefined" ? this.currentArticleSurroundings(this.currentArticle) : null;
            this.articleNeedsRefresh = false;
            this.loadedSoFar.currentArticle = true;
        },
    },
    computed: {
        ...mapState("willyou", ["isLoading", "currentArticle", "articlesCategories", "articles", "blogUsers", "blogTotalArticles"]),
        ...mapGetters("willyou", ["getCurrentMeta"]),
        getArticleHtml() {
            if (this.currentArticle.categories[0] === 450)
                return this.currentArticle.content.rendered.replace("<p>Highlights in", '<div id="willyou-cta"></div><p>Highlights in');
            return this.currentArticle.content.rendered;
        },
        allIsLoaded() {
            return this.loadedSoFar.currentArticle && this.loadedSoFar.categories && this.loadedSoFar.tags && this.loadedSoFar.author;
        },
        sidebarPosts() {
            return DiamondsNearMe;
        },
        relativePath() {
            return this.$route.path.substr(6);
        },
        getJsonLd() {
            return this.currentArticle && this.currentArticle.yoast_json_ld ? this.currentArticle.yoast_json_ld[0] : null;
        },
        isGiaArticle() {
            return this.$route.path === "/blog/diamond-certifications/gia";
        },
        articlesToShow() {
            if (!this.isCategoryArticle) {
                return this.articles ? chunk(shuffle(this.articles.filter((article) => article.id !== this.currentArticle.id)), 15)[0] : [];
            } else {
                return this.articles;
            }
        },
        pagingLinks() {
            return [
                {
                    name: "Home",
                    url: "/",
                },
                {
                    name: "Blog",
                    url: "/blog",
                },
                ...this.getCurrentBreadcrumb,
            ];
        },
        getCurrentBreadcrumb() {
            if (!this.permalink) {
                let routeArr = this.$route.params.pathMatch.split("/");
                let bcUrl = `/blog/${this.$route.params.category}`;
                let routeObj = routeArr.map((route) => {
                    bcUrl += `/${route}`;
                    return { name: route, url: bcUrl };
                });
                return [
                    {
                        name: this.$route.params.category,
                        url: `/blog/${this.$route.params.category}`,
                    },
                    ...routeObj,
                ];
            } else {
                let routeArr = [];
                let relativePath = "/blog";
                this.currentArticle.slug.split("-directoryseperator-").forEach((splittedSlug) => {
                    relativePath += `/${splittedSlug}`;
                    routeArr.push({
                        name: splittedSlug,
                        url: relativePath,
                    });
                });
                return routeArr;
            }
        },
        usersToShow() {
            return this.blogUsers;
        },
        routeProps() {
            let pathMatch = this.$route.params.pathMatch ? this.$route.params.pathMatch.split("/") : null;
            return pathMatch
                ? {
                      permalink: pathMatch[pathMatch.length - 1],
                      articleCategory: pathMatch[pathMatch.length - 2],
                  }
                : null;
        },
        getPermalink() {
            return this.permalink ? { permalink: this.permalink } : this.routeProps;
        },
        isCategoryArticle() {
            return this.permalink !== "";
        },
        isMainTitle() {
            return !this.isCategoryArticle ? "h1" : "h2";
        },
    },
    methods: {
        ...mapActions("willyou", ["getArticle", "getArticles", "getUsers"]),
        ...mapMutations("willyou", ["SET_IS_SHOW_PAGE_NOT_FOUND"]),
        isInnerHashLink(hashString) {
            return hashString.includes("#") && !hashString.includes("http");
        },
        scrollToTarget(to) {
            // Inner target scroll
            this.$scrollTo(to, 500, {
                offset: -80,
                force: true,
            });
        },
        getPostDate(date) {
            return moment(date).format("ll");
        },
        getTag(tag) {
            return convertHexToHTML(tag.name);
        },
        goToStoreLocator() {
            this.$router.push({ name: "find-a-jeweler-by-state" });
        },
        verifyPath() {
            let pathArr = this.$route.path.split("/");
            pathArr.pop();
            if (
                !this.articleNeedsRefresh &&
                this.currentArticle &&
                this.currentArticle.availableAt &&
                this.currentArticle.availableAt.indexOf(pathArr.join("/")) === -1 &&
                !this.isCategoryArticle
            ) {
                this.SET_IS_SHOW_PAGE_NOT_FOUND(true);
            }
        },
        currentArticleSurroundings(value) {
            axios.get(value._links.author[0].href).then((response) => {
                this.blogAuthor = response.data.name;
                this.loadedSoFar.author = true;
            });
            axios.get(`https://blog.willyou.net/wp-json/wp/v2/comments?post=${value.id}`).then((res) => {
                this.blogComments = res.data;
                this.loadedSoFar.components = true;
            });
            axios.get(`https://blog.willyou.net/wp-json/wp/v2/tags?post=${value.id}`).then((res) => {
                this.blogTags = res.data && res.data.length > 0 ? res.data : null;
                this.loadedSoFar.tags = true;
            });
            axios.get(`https://blog.willyou.net/wp-json/wp/v2/categories?post=${value.id}`).then((res) => {
                this.blogCategories = res.data;
                this.loadedSoFar.categories = true;
            });
        },
        checkCategoryOrArticle() {
            if (this.isCategoryArticle) return "article";
            let relativePath = this.relativePath;
            let findArticlePath = this.articlesCategories.find((cat) => cat.link === `https://blog.willyou.net/category/${relativePath}/`);
            if (relativePath === "" || (this.articlesCategories && typeof findArticlePath !== "undefined")) {
                this.$emit("changeLayoutStatus", "category");
                return "category;";
            }
            return "article";
        },
    },
    metaInfo() {
        let meta = this.currentArticle
            ? {
                  title: htmlDecode(convertHexToHTML(this.currentArticle.yoast_title)),
                  meta: this.currentArticle.yoast_meta,
              }
            : {};
        meta.meta ? (meta.meta.find((property) => property.property === "og:url").content = `https://willyou.net/${this.$route.fullPath}`) : null;
        // meta.meta  ? meta.meta.find(m => m.name=='robots').content = "noindex, nofollow":null;
        meta.link = [
            {
                rel: "canonical",
                href: `https://willyou.net${window.location.pathname}`,
            },
        ];
        return meta;
    },
};
</script>

<style lang="scss">
@use "@/assets/scss/article.scss" as *;
#blog-article {
    margin-top: 40px;
    #rendered-content {
        table {
            width: 100%;
        }
        td {
            border: 1px solid silver;
            text-align: center;
        }
        a {
            text-decoration: underline !important;
        }
        li.blocks-gallery-item {
            display: inline-block;
            width: 30%;
            margin-right: 1.5%;
        }
    }
    min-height: 100vh;
    max-width: 100% !important;
    height: auto !important;
    padding-top: 120px;
    .padding-30 {
        padding: 0 30px 0;
    }

    .article-wrapper {
        width: calc(100% - 10px);
        max-width: 700px;
        margin: auto;

        @media (min-width: 768px) {
            width: calc(100% - 40px);
        }
    }
    .article-title {
        margin: 0 354px 8px 0;
        width: calc(100% - 10px);
        max-width: 700px;
        margin: auto;

        @media (min-width: 768px) {
            width: calc(100% - 40px);
        }
    }
    .title {
        font-family: "NotoSerifSC", sans-serif;
        font-size: 36px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
    }
    #rendered-content {
        margin: 8px 0 32px;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.8);
    }

    .bradcrumbs {
        width: calc(100% - 10px);
        max-width: 700px;
        margin: auto;

        @media (min-width: 768px) {
            width: calc(100% - 40px);
        }
    }
    .sub-title {
        font-family: "NotoSans", sans-serif;
        font-size: 12px;
        letter-spacing: 2px;
        color: rgba(0, 0, 0, 0.8);
        width: calc(100% - 10px);
        max-width: 700px;
        margin: auto;

        @media (min-width: 768px) {
            width: calc(100% - 40px);
        }

        .path {
            width: 8px;
            height: 8px;
            margin: 4px;
            background-color: #767d98;
        }
    }
    .full-width {
        width: 100%;
    }
    .highlighted {
        color: #ff0a53;
    }
    #rendered-content {
        img {
            width: 100% !important;
            height: auto;
        }

        .wp-block-table {
            td {
                text-align: left;
                padding-left: 8px;
            }
        }
    }
    @media (max-width: 992px) {
        #rendered-content {
            img {
                width: 100% !important;
                height: auto;
            }
        }
    }
    @media (max-width: 767px) {
        #rendered-content {
            img {
                width: 100%;
                height: auto;
            }
        }
        #sidebar {
            display: none;
        }
    }

    .search-cert-num {
        margin: 24px auto 88px;
    }

    .article-title.low-size {
        font-size: 32px;
    }

    .loading-wrapper {
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;

        #loading {
            padding-top: 130px;
        }
    }
}
</style>
