<template>
    <div id="social-networks">
        <ul class="list-inline list-unstyled footer-list">
            <li class="list-inline-item footer-list-element">
                <a href="https://www.facebook.com/Willyou.net.Engagement.Rings.Near.Me">
                    <v-lazy-img alt="Facebook-willyou.net" :src="getSrc('facebook')" class="footer-social-icon" loading="lazy"></v-lazy-img>
                </a>
            </li>
            <li class="list-inline-item footer-list-element">
                <a href="https://twitter.com/Rings_Near_me">
                    <v-lazy-img alt="Twitter-willyou.net" :src="getSrc('twitter')" class="footer-social-icon" loading="lazy"></v-lazy-img>
                </a>
            </li>
            <li class="list-inline-item footer-list-element">
                <a href="#">
                    <v-lazy-img alt="Instagram-willyou.net" :src="getSrc('instegram')" class="footer-social-icon" loading="lazy"></v-lazy-img>
                </a>
            </li>
            <li class="list-inline-item footer-list-element">
                <a href="https://linkedin.com/company/75029209/">
                    <v-lazy-img alt="Linkedin-willyou.net" :src="getSrc('linkedin')" class="footer-social-icon" loading="lazy"></v-lazy-img>
                </a>
            </li>
            <li class="list-inline-item footer-list-element">
                <a href="https://pinterest.com/willyou_net_jewelers">
                    <v-lazy-img alt="Pinterest-willyou.net" :src="getSrc('pinterest')" class="footer-social-icon" loading="lazy"></v-lazy-img>
                </a>
            </li>
            <li class="list-inline-item footer-list-element">
                <a href="https://www.youtube.com/channel/UCmgf5MkDnLEdk1Zddzpr5ow">
                    <v-lazy-img alt="Youtube-willyou.net" :src="getSrc('youtube')" class="footer-social-icon"></v-lazy-img>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
import VLazyImage from "v-lazy-image";

export default {
    name: "social-networks",
    components: {
        "v-lazy-img": VLazyImage,
    },
    methods: {
        getSrc(fileName) {
            return require(`../../assets/images/footer/${fileName}.svg`);
        },
    },
};
</script>
