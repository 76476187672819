<template>
    <div id="topic-list" v-if="topics && topics.length">
        <div class="row">
            <div class="title mb-3">Topic List:</div>
        </div>
        <div v-for="(topic, index) in topics" :key="index" class="row">
            <div class="topic text-uppercase">+ {{ topic }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "topic-list",
    props: ["topics"],
};
</script>

<style lang="scss" scoped>
#topic-list {
    padding: 32px 186px 32px 32px;
    background-color: #ffeff5;
    .title {
        font-family: "Noto Serif SC", serif;
        font-size: 24px;
        font-weight: 200;
        color: rgba(0, 0, 0, 0.87);
    }
    .topic {
        font-family: "Noto Sans KR", sans-serif;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 2px;
        color: #ff0a53;
    }
}
</style>
