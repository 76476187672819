<template>
    <div id="blog-lobby-hero" class="text-center">
        <section class="hero-banner">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-8">
                        <div class="home-subtitle text-uppercase">
                            Last Updated {{ getYesterday }}
                            {{ getCounterHeadline }}
                        </div>
                        <h1 class="home-title">
                            {{ getTitle }}
                        </h1>
                        <div class="noto-sans-font-title">
                            {{ getDescription }}
                        </div>
                    </div>
                </div>
                <links @updateLink="updateValue" :links="categoriesToShow" :selectedLink="selectedLink"></links>
                <links v-if="!isCategoryArticle && isBlogHomePage" @updateLink="updateValue" :links="subCategoriesToShow" :selectedLink="selectedLink"></links>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex";
import Links from "../../../../components/Links";
import { getBeginningOfMonth, convertHexToHTML } from "../../../../helpers";

export default {
    components: {
        links: Links,
    },
    props: ["categories", "hierarchy", "articlesCount", "title", "description", "isCategoryArticle"],
    data() {
        return {
            selectedLink: {
                title: "all",
                src: "/",
            },
            triggerChange: null,
        };
    },
    watch: {
        triggerChange() {
            const selectedCategory = this.categories.filter((category) => {
                let linkSrc = category.link.replace("https://blog.willyou.net/category/", "/blog/");
                linkSrc = linkSrc.substring(0, linkSrc.length - 1);
                return this.$route.path === linkSrc;
            });
            let linkSrc = selectedCategory[0] ? selectedCategory[0].link.replace("https://blog.willyou.net/category/", "/blog/") : null;
            linkSrc = linkSrc ? linkSrc.substring(0, linkSrc.length - 1) : null;
            this.selectedLink = { title: selectedCategory[0] ? selectedCategory[0].name : "all", src: linkSrc ? linkSrc : "" };
        },
    },
    computed: {
        ...mapState("willyou", ["blogSpecialCategoryArticles"]),
        getYesterday() {
            return getBeginningOfMonth();
        },
        getTitle() {
            return this.title;
        },
        getDescription() {
            return this.description
                ? convertHexToHTML(this.description)
                : `Willyou.net's Blog and Newsroom is home to articles, news, and announcements from our US community of jewelry stores and jewelers.`;
        },
        categoriesToShow() {
            let categories = [];
            let categoryNumber = this.getCurrentCategory;
            let parentCategoryNumber = this.categories.filter((category) => category.id === categoryNumber.number && category.name !== "category-articles");

            // looking for childs categories
            categories = this.categories
                // filter categories by parent id of the current category.
                .filter((category) => category.parent === categoryNumber.number && category.name !== "category-articles")
                // mapping results
                .map((category) => ({
                    id: category.id,
                    title: category.name,
                    src:
                        this.$route.path === "/blog"
                            ? `${this.$route.path}/${category.slug}`
                            : parentCategoryNumber[0] && parentCategoryNumber[0].parent === category.parent
                            ? `${this.$route.path.match(/.*\//g)}${category.slug}`
                            : `${this.$route.path}/${category.slug}`,
                }));

            // looking for parent categories if not exist sub categories
            if (categories.length === 0) {
                // if (this.$route.path !== "/blog/best-places-to-propose")
                categories = this.categories
                    .filter((category) => category.parent === parentCategoryNumber[0].parent && category.name !== "category-articles")
                    .map((category) => ({
                        id: category.id,
                        title: category.name,
                        src:
                            !parentCategoryNumber[0] || parentCategoryNumber[0].parent !== 0 || category.parent === 0
                                ? `${this.$route.path.match(/.*\//g)}${category.slug}`
                                : `${this.$route.path}/${category.slug}`,
                    }));
            }
            const selectedCategory = this.categories.filter((category) => {
                let linkSrc = category.link.replace("https://blog.willyou.net/category/", "/blog/");
                linkSrc = linkSrc.substring(0, linkSrc.length - 1);
                return this.$route.path === linkSrc;
            });
            let linkSrc = selectedCategory[0] ? selectedCategory[0].link.replace("https://blog.willyou.net/category/", "/blog/") : null;
            linkSrc = linkSrc ? linkSrc.substring(0, linkSrc.length - 1) : null;

            //replace the code below with : this.triggerChange = Date.now();
            // this.selectedLink = { title: selectedCategory[0] ? selectedCategory[0].name : "all", src: linkSrc ? linkSrc : "" };
            //
            categories.unshift({ title: "all", src: "" });
            return categories;
        },
        subCategoriesToShow() {
            return this.categories
                ? this.categories.filter((category) => category.parent !== 0 && category.parent !== 7).map((category) => this.blogPostObjToLinkObj(category))
                : [];
        },
        getCurrentCategory() {
            let currentCategory =
                this.hierarchy === "lobby"
                    ? 0
                    : this.hierarchy === "category"
                    ? this.categories.filter((category) => category.slug === this.$route.params.category)
                    : this.categories.filter((category) => {
                          return category.slug === this.getCurrentSubcategory;
                      });
            return currentCategory && currentCategory[0] && currentCategory[0].id
                ? {
                      number: currentCategory[0].id,
                      slug: currentCategory[0].slug,
                  }
                : { number: 0, slug: "" };
        },
        getCurrentSubcategory() {
            let path = this.$route.params.pathMatch ? this.$route.params.pathMatch.split("/") : null;
            const splittedPath = window.location.pathname.split("/");
            if (this.isCategoryArticle) return splittedPath[splittedPath.length - 1];
            return path ? path[path.length - 1] : null;
        },
        getQuery() {
            return {
                category: this.selectedLink && this.selectedLink.title ? this.selectedLink.title : "",
            };
        },
        getCounterHeadline() {
            return this.articlesCount > 0 ? ` - ${this.articlesCount} articles` : ``;
        },
        isBlogHomePage() {
            return this.$route.path === "/blog";
        },
    },
    methods: {
        updateValue(link) {
            this.selectedLink = link;
            this.applyFilters();
        },
        applyFilters() {
            if (this.selectedLink && this.selectedLink.title === "all") return this.$router.push({ name: "blog" });

            if (this.hierarchy === "lobby")
                return this.$router.push({
                    name: "blog-category",
                    params: {
                        category: this.selectedLink.title.replace(/\s/g, "-").replace(/'/g, "").toLowerCase(),
                    },
                });
            if (this.hierarchy === "category")
                return this.$router.push({
                    name: "blog-sub-category",
                    params: {
                        subcategory: this.selectedLink.title.replace(/\s/g, "-").replace(/'/g, "").toLowerCase(),
                        category: this.getCurrentCategory.slug,
                    },
                });

            this.triggerChange = Date.now();
        },
        updateDataFromUrl() {
            this.triggerChange = Date.now();
            this.currentShapes = this.$route.query.shapes ? this.returnArray(this.$route.query.shapes) : [];
            this.currentClarity = this.$route.query.clarity ? this.returnArray(this.$route.query.clarity) : [];
            this.currentColors = this.$route.query.colors ? this.returnArray(this.$route.query.colors) : [];
            this.currentCuts = this.$route.query.cuts ? this.returnArray(this.$route.query.cuts) : [];
        },
        blogPostObjToLinkObj(category) {
            const linkTitle = category.slug.replace("/^(.*?)-directoryseperator-/", "").replaceAll("-", " ");
            this.triggerChange = Date.now();
            let linkSrc = category.link.replace("https://blog.willyou.net/category/", "/blog/");
            linkSrc = linkSrc.substring(0, linkSrc.length - 1);

            return {
                id: category.id,
                title: linkTitle === "4cs" ? "4c's" : linkTitle,
                src: linkSrc,
            };
        },
    },
    mounted() {
        this.updateDataFromUrl();
    },
};
</script>

<style lang="scss" scoped>
#blog-lobby-hero {
    .hero-banner {
        position: relative;
        width: 100%;
        padding-top: 20px;
    }

    @media (max-width: 767px) {
        .hero-banner {
            min-height: 300px;
        }
        .home-title {
            font-size: 48px;
        }
        .home-subtitle {
            text-align: start;
        }
    }
}
</style>
