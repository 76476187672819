<script>
export default {
    computed: {
        getArticlesLength() {
            return this.blogTotalArticles;
        },
        currentCategory() {
            return this.articlesCategories
                ? this.articlesCategories.find((cat) => cat.link === `https://blog.willyou.net/category/${this.relativePath}/`)
                : null;
        },
        getHeroTitle() {
            return this.currentCategory && this.currentCategory.name ? this.currentCategory.name.toUpperCase() : "BLOG & NEWS";
        },
        getHeroDescription() {
            return this.getYoastDescription;
        },
        getYoastDescription() {
            let description = this.currentCategory && this.currentCategory.yoast ? this.currentCategory.yoast.match(/<meta name="description"(.*?)\/>/g) : "";
            description = description && description[0] ? description[0].match(/"(.*?)"/g) : "";
            description = description && description[1] ? description[1] : "";
            description = description.match(/([a-zA-Z])(.*?)(?=")/g);
            return description && description[0] ? description[0] : "";
        },
    },
};
</script>
