<template>
    <div id="blog-lobby">
        <div class="loading-wrapper" v-if="isLoading || !allIsLoaded || isEmitted">
            <loading></loading>
        </div>
        <div v-if="!isLoading && allIsLoaded" class="pt-5">
            <div class="container">
                <paging-navigator class="bradcrumbs" :links="pagingLinks" :no-slashes="true"></paging-navigator>
                <hero
                    :categories="articlesCategories"
                    :hierarchy="getHierarchy"
                    :articles-count="getArticlesLength"
                    :title="getHeroTitle"
                    :description="getHeroDescription"></hero>
                <cards-area :articles="articlesToShow" :users="usersToShow"></cards-area>
                <paging v-if="getPagesCount && getPagesCount > 1" :pages="getPagesCount"></paging>
            </div>
        </div>
        <div v-if="showLdScript !== ''" v-html="showLdScript"></div>
        <successfully-loaded-indicator></successfully-loaded-indicator>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { convertHexToHTML, htmlDecode } from "../../../helpers";

import Loading from "../../../components/Loading.vue";
import Paging from "../../../components/Paging.vue";
import PagingNavigatorVue from "../../../components/PagingNavigator.vue";
import SuccessfullyLoadedVue from "../../../components/SuccessfullyLoaded.vue";
import CategoryHeroMixinVue from "../mixins/CategoryHeroMixin.vue";
import CardsArea from "./components/CardsArea";
import Hero from "./components/Hero.vue";

export default {
    name: "blog-lobby",
    mixins: [CategoryHeroMixinVue],
    components: {
        hero: Hero,
        "cards-area": CardsArea,
        paging: Paging,
        loading: Loading,
        "paging-navigator": PagingNavigatorVue,
        "successfully-loaded-indicator": SuccessfullyLoadedVue,
    },
    data() {
        return {
            elementsInPage: 6,
            isArticle: false,
            isEmitted: false,
            isSentRequest: false,
            showLdScript: "",
        };
    },
    mounted() {
        this.getArticles({ payload: this.routeProps, router: this.$router });
        this.blogUsers.length === 0 ? this.getUsers() : null;
    },
    watch: {
        routeProps() {
            if (this.isSentRequest) return;
            this.getArticles({
                payload: this.routeProps,
                router: this.$router,
            });
            this.isSentRequest = true;
        },
        $route(newVal, oldVal) {
            window.scrollTo(0, 0);
            this.checkCategoryOrArticle();
            this.isSentRequest = false;
            if (newVal.query.page && oldVal.query.page && oldVal.query.page === newVal.query.page) return;
            this.getArticles({
                payload: this.routeProps,
                router: this.$router,
            });
            this.isSentRequest = true;
        },
        allIsLoaded() {
            this.checkCategoryOrArticle();
        },
    },
    computed: {
        ...mapState("willyou", ["articles", "articlesCategories", "isLoading", "blogUsers", "blogTotalPages", "blogTotalArticles"]),
        ...mapGetters("willyou", ["getCurrentMeta"]),
        routeProps() {
            if (this.relativePath === "") return null;
            let route = this.relativePath.split("/");

            if (route.length > 1)
                return {
                    category: route[0],
                    subcategory: route[route.length - 1],
                };

            return { category: route[0] };
        },
        relativePath() {
            return this.$route.path.substr(6);
        },
        allIsLoaded() {
            return this.articles.length && this.articlesCategories.length && this.blogUsers.length;
        },
        pagingLinks() {
            return [
                {
                    name: "Home",
                    url: "/",
                },
                {
                    name: "Blog",
                    url: "/blog",
                },
                ...this.getCurrentBreadcrumb,
            ];
        },
        getCurrentBreadcrumb() {
            let category = this.relativePath !== "" ? this.relativePath.split("/") : null;
            return category ? this.makePathBreadcrumbsObj(category) : [];
        },
        getHierarchy() {
            return this.$route.params.pathMatch ? "subcategory" : this.$route.params.category ? "category" : "lobby";
        },
        currentPage() {
            return parseInt(this.$route.query.page ? this.$route.query.page : 1);
        },
        articlesToShow() {
            return this.articles;
        },
        getPagesCount() {
            return this.blogTotalPages;
        },
        usersToShow() {
            return this.blogUsers;
        },
    },
    methods: {
        ...mapActions("willyou", ["getArticles", "getArticlesCategories", "getUsers"]),
        checkCategoryOrArticle() {
            if (this.articlesCategories && this.articlesCategories.length > 0) {
                let relativePath = this.relativePath;
                let findArticlePath = this.articlesCategories.find((cat) => cat.link === `https://blog.willyou.net/category/${relativePath}/`);
                if (relativePath === "" || (this.articlesCategories && typeof findArticlePath !== "undefined")) {
                    this.isArticle = false;
                    return "category";
                } else {
                    this.$emit("changeLayoutStatus", "article");
                    this.isArticle = true;
                    return "article";
                }
            }
            return "category";
        },
        makePathBreadcrumbsObj(categories) {
            let breadCrumbs = [];
            let currentUri = "/blog";
            let categoryName = "";
            for (let i = 0; i < categories.length; i++) {
                categoryName = categories[i];
                currentUri += `/${categoryName}`;
                breadCrumbs.push({ name: categoryName, url: currentUri });
            }
            return breadCrumbs;
        },
        getTitleFromYoastInfo(yoastInfo = "") {
            let title = yoastInfo.match(/<title>(.*?)<\/title>/g)[0];
            title = title.replace(/<title>/g, "").replace(/<\/title>/g, "");
            return htmlDecode(convertHexToHTML(title));
        },
        putJsonLdScript(yoastInfo = "") {
            let jsonLd = yoastInfo.match(/<script(.*?)<\/script>/g)[0];
            jsonLd = jsonLd.replace(/blog.willyou.net/g, "willyou.net/blog");
            this.showLdScript = jsonLd;
        },
    },
    metaInfo() {
        let currentMeta = this.getCurrentMeta;
        let itemType = this.checkCategoryOrArticle();
        if (this.articlesCategories.length > 0 && this.$route.params.category && itemType === "category") {
            let currrentSubCat = this.$route.params.pathMatch ? this.$route.params.pathMatch.split("/") : [this.$route.params.category];
            currrentSubCat = currrentSubCat[currrentSubCat.length - 1];
            let subCatYoastInfo = this.articlesCategories.find((cat) => cat.slug === currrentSubCat).yoast;
            const draftTitle = this.getTitleFromYoastInfo(subCatYoastInfo);
            currentMeta.title = draftTitle.length > 60 ? draftTitle : `${draftTitle} | Willyou.net`;
            this.getYoastDescription
                ? (currentMeta.meta.find((meta) => meta.name === "description").content = convertHexToHTML(this.getYoastDescription))
                : null;
            this.putJsonLdScript(subCatYoastInfo);
        }

        if (this.$route.name === "blog" && this.$route.query.page)
            currentMeta.link.find((item) => item.rel === "canonical").href = `${window.location.origin + this.$route.path}?page=${this.$route.query.page}`;

        return currentMeta;
    },
};
</script>

<style lang="scss" scoped>
#blog-lobby {
    min-height: 100vh;
    background-color: #f7f7f7;

    .container {
        margin-top: 92px;
    }

    .loading-wrapper {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
