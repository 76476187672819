<template>
    <div id="categories">
        <div class="row">
            <div class="title mb-3">Diamonds Near me</div>
        </div>
        <div class="row">
            <div v-for="(post, index) in posts" :key="index">
                <post-card :post="post"></post-card>
            </div>
        </div>
    </div>
</template>

<script>
import PostCard from "./PostCard.vue";
export default {
    name: "sidebar-posts",
    props: ["posts"],
    components: {
        "post-card": PostCard,
    },
};
</script>

<style lang="scss" scoped>
#categories {
    .title {
        font-family: "Noto Serif SC", serif;
        font-size: 24px;
        font-weight: 200;
        color: rgba(0, 0, 0, 0.87);
    }
    .category {
        margin: 0 12px 12px 0;
        padding-right: 14px;
        padding-left: 14px;
        border: solid 1px #767d98;
        .category-title {
            font-family: Noto Sans KR, sans-serif;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: rgba(0, 0, 0, 0.8);
        }
        .category-title::after {
            content: "";
            border-right: 1px solid #767d98;
            margin: 0 0 0 0.5rem;
        }
        .category-number {
            font-family: Noto Sans KR, sans-serif;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: #ff0a53;
        }
    }
}
</style>
