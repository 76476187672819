<template>
    <div id="links">
        <section class="links-section pb-5">
            <div class="container text-center">
                <div class="row justify-content-center link-row">
                    <span
                        class="child link-span px-3 py-2"
                        :class="selectedLink.title === link.title ? 'link-span-selected' : null"
                        v-for="(link, i) in links"
                        :key="i"
                        @click="updateValue(link)">
                        <router-link :to="link.src" class="link-title text-uppercase">{{ link.title }}</router-link>
                    </span>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "links",
    props: ["links", "selectedLink"],
    methods: {
        updateValue(link) {
            if (this.$route.path !== link.src) {
                this.$router.push({ path: link.src });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#links {
    color: rgba(48, 2, 2, 0.87);
    .links-section {
        .link-span-selected {
            pointer-events: none;
            background-color: #ffeff5 !important;
        }
        .link-row .child {
            margin-top: -2px;
            margin-left: -2px;
        }
        .link-span {
            transition: 0.25s all;
            background-color: #fff;
            border: solid 1.5px #c3c3c3;

            a {
                display: block;
            }
        }
        .link-span:hover {
            background-color: #ffeff5;
        }
        .link-title {
            font-size: 16px;
            font-weight: 200;
            letter-spacing: 1.5px;
            font-family: "Noto Serif SC", serif;
        }
    }
}
</style>
