<template>
    <div id="banner">
        <div class="image-container">
            <div class="message">{{ message }}</div>
            <button @click="goToStoreLocator" class="banner-button text-uppercase">{{ button }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "banner",
    props: ["message", "button"],
    methods: {
        goToStoreLocator() {
            this.$router.push({ name: "find-a-jeweler-by-state" });
        },
    },
};
</script>

<style lang="scss">
#banner {
    .message {
        font-family: "Satisfy", cursive;
        font-size: 26px;
        text-align: center;
        color: #000000;
    }
    .banner-button {
        width: 102px;
        height: 48px;
        background-color: #ff4d68;
        font-family: Noto Sans KR, sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.57px;
        text-align: center;
        color: #ffffff;
        border: none;
    }
    .image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("../../../../../assets/images/blog/banner-background.svg");
        background-size: contain;
        width: 221px;
        height: 221px;
    }
}
</style>
