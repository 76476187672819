const DiamondsNearMe = [
    {
        internalID: "f4a1bc678ea4ee6ffc931bf486f3988e",
        internal_lot: "15239a15",
        name: "2 Carat F-SI1 Oval Diamond",
        Clarity: "SI1",
        Color: "F",
        Cut: "-",
        Depth: "62.1",
        FlourIntensity: "N",
        Lab: "GIA",
        Measurements: "9.63x7.19x4.46",
        Polish: "EX",
        Symmetry: "VG",
        Shape: "Oval",
        Tablet: "59.0",
        Weight: 2,
        Media: [
            {
                type: "certificate",
                src: "https://willyou.net/DiamondMedia/15239a15_ldf/certificate/15239a15_Certificate.png",
            },
            {
                type: "picture",
                src: "https://willyou.net/DiamondMedia/15239a15_ldf/picture/15239a15.jpg",
            },
            {
                type: "thumbnail-sm",
                src: "https://willyou.net/DiamondMedia/15239a15_ldf/thumbnails/250/15239a15.jpg",
            },
            {
                type: "thumbnail-md",
                src: "https://willyou.net/DiamondMedia/15239a15_ldf/thumbnails/350/15239a15.jpg",
            },
            {
                type: "video",
                src: "https://willyou.net/DiamondMedia/15239a15_ldf/video/15239a15.mp4",
            },
        ],
        Price: false,
        TotalPrice: null,
    }, //eslint-disable-line
    {
        internalID: "9d64083e6169dde551d199de009677f5",
        internal_lot: "16076a07",
        name: "2.01 Carat H-VS1 Emerald Diamond",
        Clarity: "VS1",
        Color: "H",
        Cut: "-",
        Depth: "69.7",
        FlourIntensity: "N",
        Lab: "GIA",
        Measurements: "8.17x6.09x4.25",
        Polish: "EX",
        Symmetry: "EX",
        Shape: "Emerald",
        Tablet: "65.0",
        Weight: 2.01,
        Media: [
            {
                type: "certificate",
                src: "https://willyou.net/DiamondMedia/16076a07_ldf/certificate/16076a07_Certificate.png",
            },
            {
                type: "picture",
                src: "https://willyou.net/DiamondMedia/16076a07_ldf/picture/16076a07.jpg",
            },
            {
                type: "thumbnail-sm",
                src: "https://willyou.net/DiamondMedia/16076a07_ldf/thumbnails/250/16076a07.jpg",
            },
            {
                type: "thumbnail-md",
                src: "https://willyou.net/DiamondMedia/16076a07_ldf/thumbnails/350/16076a07.jpg",
            },
            {
                type: "video",
                src: "https://willyou.net/DiamondMedia/16076a07_ldf/video/16076a07.mp4",
            },
        ],
        Price: false,
        TotalPrice: null,
    }, //eslint-disable-line
    {
        internalID: "cb6f96cc150ea2f29fc1500840f8bf52",
        internal_lot: "16182a02",
        name: "2.01 Carat H-SI1 Princess Diamond",
        Clarity: "SI1",
        Color: "H",
        Cut: "-",
        Depth: "70.1",
        FlourIntensity: "N",
        Lab: "GIA",
        Measurements: "7.18x7.09x4.97",
        Polish: "EX",
        Symmetry: "EX",
        Shape: "Princess",
        Tablet: "72.0",
        Weight: 2.01,
        Media: [
            {
                type: "certificate",
                src: "https://willyou.net/DiamondMedia/16182a02_ldf/certificate/16182a02_Certificate.png",
            },
            {
                type: "picture",
                src: "https://willyou.net/DiamondMedia/16182a02_ldf/picture/16182a02.jpg",
            },
            {
                type: "thumbnail-sm",
                src: "https://willyou.net/DiamondMedia/16182a02_ldf/thumbnails/250/16182a02.jpg",
            },
            {
                type: "thumbnail-md",
                src: "https://willyou.net/DiamondMedia/16182a02_ldf/thumbnails/350/16182a02.jpg",
            },
            {
                type: "video",
                src: "https://willyou.net/DiamondMedia/16182a02_ldf/video/16182a02.mp4",
            },
        ],
        Price: false,
        TotalPrice: null,
    }, //eslint-disable-line
    {
        internalID: "9adc44e2456ab095b57dedf288193492",
        internal_lot: "14710a02",
        name: "2.01 Carat G-VS2 Round Diamond",
        Clarity: "VS2",
        Color: "G",
        Cut: "EX",
        Depth: "62.6",
        FlourIntensity: "F",
        Lab: "GIA",
        Measurements: "8.06x8.03x5.03",
        Polish: "EX",
        Symmetry: "EX",
        Shape: "Round",
        Tablet: "57.0",
        Weight: 2.01,
        Media: [
            {
                type: "certificate",
                src: "https://willyou.net/DiamondMedia/14710a02_ldf/certificate/14710a02_Certificate.png",
            },
            {
                type: "picture",
                src: "https://willyou.net/DiamondMedia/14710a02_ldf/picture/14710a02.jpg",
            },
            {
                type: "thumbnail-sm",
                src: "https://willyou.net/DiamondMedia/14710a02_ldf/thumbnails/250/14710a02.jpg",
            },
            {
                type: "thumbnail-md",
                src: "https://willyou.net/DiamondMedia/14710a02_ldf/thumbnails/350/14710a02.jpg",
            },
            {
                type: "video",
                src: "https://willyou.net/DiamondMedia/14710a02_ldf/video/14710a02.mp4",
            },
        ],
        Price: false,
        TotalPrice: null,
    }, //eslint-disable-line
    {
        internalID: "a831c2e981f683f66dd50cc5e47cf032",
        internal_lot: "15316a03",
        name: "2.04 Carat F-VS2 Pear Diamond",
        Clarity: "VS2",
        Color: "F",
        Cut: "-",
        Depth: "63.6",
        FlourIntensity: "F",
        Lab: "GIA",
        Measurements: "10.72x7.13x4.54",
        Polish: "EX",
        Symmetry: "EX",
        Shape: "Pear",
        Tablet: "60.0",
        Weight: 2.04,
        Media: [
            {
                type: "certificate",
                src: "https://willyou.net/DiamondMedia/15316a03_ldf/certificate/15316a03_Certificate.png",
            },
            {
                type: "picture",
                src: "https://willyou.net/DiamondMedia/15316a03_ldf/picture/15316a03.jpg",
            },
            {
                type: "thumbnail-sm",
                src: "https://willyou.net/DiamondMedia/15316a03_ldf/thumbnails/250/15316a03.jpg",
            },
            {
                type: "thumbnail-md",
                src: "https://willyou.net/DiamondMedia/15316a03_ldf/thumbnails/350/15316a03.jpg",
            },
            {
                type: "video",
                src: "https://willyou.net/DiamondMedia/15316a03_ldf/video/15316a03.mp4",
            },
        ],
        Price: false,
        TotalPrice: null,
    }, //eslint-disable-line
    {
        internalID: "6bbdaeabd90b95204175b3fc4906c100",
        internal_lot: "14340a03",
        name: "2.01 Carat F-VS2 Cushion Diamond",
        Clarity: "VS2",
        Color: "F",
        Cut: "-",
        Depth: "69.9",
        FlourIntensity: "N",
        Lab: "GIA",
        Measurements: "7.42x7.00x4.89",
        Polish: "VG",
        Symmetry: "VG",
        Shape: "Cushion",
        Tablet: "61.0",
        Weight: 2.01,
        Media: [
            {
                type: "certificate",
                src: "https://willyou.net/DiamondMedia/14340a03_ldf/certificate/14340a03_Certificate.png",
            },
            {
                type: "picture",
                src: "https://willyou.net/DiamondMedia/14340a03_ldf/picture/14340a03.jpg",
            },
            {
                type: "thumbnail-sm",
                src: "https://willyou.net/DiamondMedia/14340a03_ldf/thumbnails/250/14340a03.jpg",
            },
            {
                type: "thumbnail-md",
                src: "https://willyou.net/DiamondMedia/14340a03_ldf/thumbnails/350/14340a03.jpg",
            },
            {
                type: "video",
                src: "https://willyou.net/DiamondMedia/14340a03_ldf/video/14340a03.mp4",
            },
        ],
        Price: false,
        TotalPrice: null,
    }, //eslint-disable-line
    {
        internalID: "574f52aa746925db0623917a3eefbcd0",
        internal_lot: "16095a03",
        name: "2.01 Carat H-VS2 Marquise Diamond",
        Clarity: "VS2",
        Color: "H",
        Cut: "-",
        Depth: "62.7",
        FlourIntensity: "N",
        Lab: "GIA",
        Measurements: "13.29x6.63x4.16",
        Polish: "EX",
        Symmetry: "EX",
        Shape: "Marquise",
        Tablet: "59.0",
        Weight: 2.01,
        Media: [
            {
                type: "certificate",
                src: "https://willyou.net/DiamondMedia/16095a03_ldf/certificate/16095a03_Certificate.png",
            },
            {
                type: "picture",
                src: "https://willyou.net/DiamondMedia/16095a03_ldf/picture/16095a03.jpg",
            },
            {
                type: "thumbnail-sm",
                src: "https://willyou.net/DiamondMedia/16095a03_ldf/thumbnails/250/16095a03.jpg",
            },
            {
                type: "thumbnail-md",
                src: "https://willyou.net/DiamondMedia/16095a03_ldf/thumbnails/350/16095a03.jpg",
            },
            {
                type: "video",
                src: "https://willyou.net/DiamondMedia/16095a03_ldf/video/16095a03.mp4",
            },
        ],
        Price: false,
        TotalPrice: null,
    }, //eslint-disable-line
    {
        internalID: "53632ae5d0891697d89e2bfd8f7ba17f",
        internal_lot: "15647a10",
        name: "2.01 Carat H-VS2 Radiant Diamond",
        Clarity: "VS2",
        Color: "H",
        Cut: "-",
        Depth: "67.6",
        FlourIntensity: "N",
        Lab: "GIA",
        Measurements: "8.03x6.43x4.34",
        Polish: "EX",
        Symmetry: "VG",
        Shape: "Radiant",
        Tablet: "65.0",
        Weight: 2.01,
        Media: [
            {
                type: "certificate",
                src: "https://willyou.net/DiamondMedia/15647a10_ldf/certificate/15647a10_Certificate.png",
            },
            {
                type: "picture",
                src: "https://willyou.net/DiamondMedia/15647a10_ldf/picture/15647a10.jpg",
            },
            {
                type: "thumbnail-sm",
                src: "https://willyou.net/DiamondMedia/15647a10_ldf/thumbnails/250/15647a10.jpg",
            },
            {
                type: "thumbnail-md",
                src: "https://willyou.net/DiamondMedia/15647a10_ldf/thumbnails/350/15647a10.jpg",
            },
            {
                type: "video",
                src: "https://willyou.net/DiamondMedia/15647a10_ldf/video/15647a10.mp4",
            },
        ],
        Price: false,
        TotalPrice: null,
    }, //eslint-disable-line
];
export default DiamondsNearMe;
