<template>
    <div id="common-questions">
        <section class="py-4" v-if="questions[0].Answer">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col">
                        <h2 class="home-title mb-md-5 pb-4">Common Questions</h2>
                    </div>
                </div>
                <div class="row justify-content-left data-area">
                    <div class="col-md-12 col-lg-6" v-for="(questions, i) in questionsArrays" :key="i">
                        <div class="questions-area text-left py-3" v-for="(q, index) in questions" :key="index">
                            <button
                                class="btn btn-link footer-collapse-btn d-block w-100 text-left pl-0"
                                data-toggle="collapse"
                                :data-target="`#answer-${i}-${index}`"
                                aria-expanded="true"
                                aria-controls="collapse-about"
                                @click="updateOpenedQuestion(q.Question)">
                                <span class="text-uppercase list-heading">{{ q.Question }}</span>
                                <span class="float-right icon">{{ questionsOpened.includes(q.Question) ? "-" : "+" }}</span>
                            </button>
                            <ul :id="`answer-${i}-${index}`" class="list-unstyled footer-list collapse m-0">
                                <li class="list-element">
                                    <span v-html="q.Answer.replace(/\n/g, '<br />')"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import chunk from "lodash/chunk";

export default {
    name: "common-questions",
    props: ["questions"],
    data() {
        return {
            questionsOpened: [],
        };
    },
    computed: {
        questionsArrays() {
            return chunk(this.questions, 3);
        },
    },
    methods: {
        updateOpenedQuestion(q) {
            this.questionsOpened = this.questionsOpened.includes(q)
                ? this.questionsOpened.filter((question) => question !== q)
                : this.questionsOpened.concat(q);
        },
    },
};
</script>

<style lang="scss" scoped>
#common-questions {
    .hero-subtext {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        font-family: "Noto Sans KR", sans-serif;
    }
    .list-heading {
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
        font-size: 16px;
        margin-top: 30px;
        display: inline;
    }
    .list-element {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.1px;
        color: #4a4a4a;
        font-family: "Noto Sans KR", sans-serif;
    }
    .icon {
        color: #4a4a4a;
        font-size: 18px;
    }
    .questions-area {
        border-top: 1px solid #767d985e;
    }
    @media (max-width: 767px) {
        .data-area {
            margin: auto;
            h2.step-num {
                padding-left: 10px;
            }
        }
    }
}
</style>
