<template>
    <div class="col-12 col-md-12 d-flex justify-content-center" id="search-cert">
        <input
            class="form-control text-capitalize d-inline-block"
            @keyup="onlyNum(giaReportNumber)"
            @keyup.enter="searchByReportNumber"
            v-model="giaReportNumber"
            placeholder="GIA Report Number" />
        <button aria-label="search" class="btn rnd-secondary-btn text-uppercase inline-block ml-4" @click="searchByReportNumber">search</button>
    </div>
</template>

<script>
import { onlyNumbers } from "../../../helpers";

export default {
    name: "searchByCertificate",
    data() {
        return {
            giaReportNumber: this.$route.params.certificate ? this.$route.params.certificate : "",
        };
    },
    methods: {
        searchByReportNumber() {
            if (this.giaReportNumber.length > 0 && Number(this.giaReportNumber) && this.$route.params.certificate !== this.giaReportNumber) {
                window.open(`https://www.gia.edu/report-check?reportno=${this.giaReportNumber}`);
                // this.$router.push({
                //   name: "gia-certificate-results",
                //   params: { certificate: this.giaReportNumber },
                // });
            }
        },
        onlyNum(string) {
            this.giaReportNumber = onlyNumbers(string);
        },
    },
};
</script>

<style lang="scss" scoped>
input.d-inline-block {
    width: 225px;
    height: 48px;
    padding: 12px 12px 11px 20px;
    border: solid 1px #b8b8b8;
}

button.btn {
    margin: 0;
    display: block;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    font-stretch: normal;
    letter-spacing: 0.57px;
}
</style>
