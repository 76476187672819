<template>
    <div id="blog-loader">
        <blog-lobby v-if="!showArticle" @changeLayoutStatus="changeStatus"></blog-lobby>
        <blog-article v-if="showArticle" @changeLayoutStatus="changeStatus" :permalink="specialArticlePermalink"></blog-article>
        <jeweler-ai-banner :class="[showArticle && 'jeweler-ai-banner']"></jeweler-ai-banner>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ArticleVue from "./article/Article.vue";
import BlogLobbyVue from "./loby/BlogLobby.vue";
import JewelerAiBanner from "@/willyou/components/JewelerAiBanner.vue";

export default {
    name: "blog-loader",
    data() {
        return {
            isArticle: false,
        };
    },
    components: {
        "blog-lobby": BlogLobbyVue,
        "blog-article": ArticleVue,
        "jeweler-ai-banner": JewelerAiBanner,
    },
    computed: {
        ...mapState("willyou", ["blogSpecialCategoryArticles"]),
        // "category-articles" category in Wordpress contains the articles should be injected inside a category page. (SEO Requirement)
        // This category is hidden in the FED.
        getCurrentCategoryArticle() {
            return this.blogSpecialCategoryArticles.find((article) => {
                return article.slug === this.expectedSlugForThisCat;
            });
        },
        isCurrentCategoryArticle() {
            return !this.getCurrentCategoryArticle ? false : true;
        },
        expectedSlugForThisCat() {
            return this.$route.path.replace("/blog/", "").split("/").join("-directoryseperator-");
        },
        specialArticlePermalink() {
            return this.getCurrentCategoryArticle && this.getCurrentCategoryArticle.slug ? this.getCurrentCategoryArticle.slug : "";
        },
        showArticle() {
            return this.isArticle || this.isCurrentCategoryArticle;
        },
    },
    methods: {
        changeStatus(newStatus) {
            this.isArticle = newStatus === "article";
        },
    },
};
</script>

<style lang="scss" scoped>
#blog-loader {
    padding-top: 24px;
    .jeweler-ai-banner {
        margin-top: 40px;
    }
}
</style>
