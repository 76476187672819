<template>
    <div id="keep-in-touch">
        <div class="col-xl col-lg col-md-4 col-xs-12 col-sm-12">
            <ul class="list-inline list-unstyled footer-list">
                <li class="list-inline-item footer-list-element">
                    <a
                        rel="nofollow noopener"
                        aria-label="Share on Facebook"
                        :href="`https://www.facebook.com/sharer/sharer.php?u=https://willyou.net${this.$route.fullPath}`"
                        target="_blank">
                        <img alt="Facebook-willyou.net" src="../../../../../assets/images/footer/facebook.svg" class="footer-social-icon" />
                    </a>
                </li>
                <li class="list-inline-item footer-list-element">
                    <a
                        rel="nofollow noopener"
                        aria-label="Share on Twitter"
                        :href="`http://twitter.com/share?&amp;url=https://willyou.net${this.$route.fullPath}`"
                        target="_blank">
                        <img alt="Twitter-willyou.net" src="../../../../../assets/images/footer/twitter.svg" class="footer-social-icon" />
                    </a>
                </li>
                <li class="list-inline-item footer-list-element">
                    <a href="#">
                        <img alt="Instagram-willyou.net" src="../../../../../assets/images/footer/instegram.svg" class="footer-social-icon" />
                    </a>
                </li>
                <li class="list-inline-item footer-list-element">
                    <a href="#">
                        <img alt="Linkedin-willyou.net" src="../../../../../assets/images/footer/linkedin.svg" class="footer-social-icon" />
                    </a>
                </li>
                <li class="list-inline-item footer-list-element">
                    <a href="#">
                        <img alt="Youtube-willyou.net" src="../../../../../assets/images/footer/youtube.svg" class="footer-social-icon" />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "keep-in-touch",
};
</script>

<style lang="scss" scoped>
.footer-list-element {
    padding-top: 30px;
    a {
        color: rgba(74, 74, 74, 0.87);
    }
}
</style>
