<template>
    <div class="post-card-wrapper">
        <div class="card post-card">
            <div class="card-body p-0 mt-3 mb-3">
                <div class="row">
                    <div class="col-4">
                        <img class="mx-md-auto m-auto d-block img-fluid" alt="image" :src="post.Media.find((m) => m.type === 'thumbnail-sm').src" />
                    </div>
                    <div class="col-8">
                        <div class="box h-100 d-flex justify-content-center flex-column details-area">
                            <div class="row mb-4">
                                <div>{{ post.name }}</div>
                            </div>
                            <div class="row">
                                <router-link :to="goToDiamondsSearch" class="btn btn-link choose-btn">
                                    <span class="btn-text">{{ getLinkTitle(post) }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDiamondShapeProfessionalName } from "./../../../../helpers";
export default {
    name: "post-card",
    props: ["post"],
    computed: {
        goToDiamondsSearch() {
            return `/diamonds?shapes=${this.post.Shape.toLowerCase()}`;
        },
        goToStoreLocator() {
            return { name: "find-a-jeweler-by-state" };
        },
    },
    methods: {
        getLinkTitle(post) {
            let relevant = `View ${getDiamondShapeProfessionalName(post.Shape)}s`;
            return relevant ? relevant : post.Shape;
        },
    },
};
</script>

<style lang="scss">
.post-card-wrapper {
    .post-card {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid rgba(118, 125, 152, 0.4);
        .details-area {
            font-family: Noto Sans KR, sans-serif;
            font-size: 14px;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.8);
        }
        .choose-btn {
            font-size: 16px;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #000000;
            padding: 0;
            .btn-text {
                color: #ff0a53;
            }
        }
    }
    @media (max-width: 767px) {
        .details-area div {
            font-size: 12px;
        }
    }
}
</style>
