<script>
export default {
    data() {
        return {
            questions: [
                {
                    Question: "What is a GIA report?",
                    Answer:
                        "A GIA report is a complete, scientific assessment of a diamond’s 4C’s: color, clarity, carat, and cut. It is provided by the GIA (Gemological Institute of America), the leading gem lab in the diamond industry, and the benchmark for all other certifications. A GIA certification, or report, is regarded as the most distinguished document a diamond can receive because GIA is the world’s most respected gemstone laboratory. \n" +
                        "\n" +
                        "The report, itself, measures each diamond on its color, clarity, carat and cut and is known for having the strictest and most consistent grading system. It provides a full description of the diamond’s color, weight, measurements, and cutting style, as well as including any known treatments. It also contains additional grading information, such as a diamond’s polish, symmetry, fluorescence, and any laser inscriptions it has. \n" +
                        "\n" +
                        "A GIA report can be recognized by its microprint lines, hologram, and other security components, as well as being laminated. This prevents any tampering of the certificate while preserving the report over time. The final GIA report is then placed in a protective sleeve and delivered back to the owner. \n",
                },
                {
                    Question: "What does GIA do?",
                    Answer:
                        "GIA (the Gemological Institute of America) is a nonprofit and the leading independent gem lab in the diamond industry that evaluates diamonds and provides certificates (GIA reports) to verify their authenticity. A GIA report is known to be the benchmark for all other certifications, and the most distinguished certificate a diamond can receive. GIA is the most strict and consistent of all the grading systems, while other grading reports can be more relaxed and inconsistent. \n" +
                        "\n" +
                        "The GIA changed the way both jewelry stores and the general public would come to know about diamonds by recognizing the need for a standardized approach in grading diamonds and gems. Therefore, each GIA diamond evaluation goes through the same process, ensuring that all diamond evaluations are the same and standardized. \n" +
                        "The GIA’s mission is to: “Ensure the public trust in gems and jewelry by upholding the highest standards of integrity, academics, science, and professionalism through education, research, laboratory services, and instrument development.” They understand the importance of ensuring that you’re aware of the true and real quality of your diamond.",
                },
                {
                    Question: "How I read and verify my GIA report?",
                    Answer:
                        "A GIA report is the most distinguished document a diamond receives and contains a full scientific and unbiased assessment of your diamond’s 4Cs: carat, clarity, color, and cut. GIA reports provide a complete description of the diamond’s color, weight, measurements, and cutting style, as well as including any known treatments. It also contains additional grading information, such as a diamond’s polish, symmetry, fluorescence, and any laser inscriptions it has. \n" +
                        "\n" +
                        "Along with numerical grades, your report will come with reference diagrams. These are plot diagrams that will show the characteristics, a blemish or inclusion, of the diamond and where it is located in the diamond. The report will also display a diagram with the proportions, percentages, and angles of your diamond.\n" +
                        "\n" +
                        "To check your GIA report, you’ll want to find the GIA Report Number. Each report has its own GIA Report Number, which is a unique number for reporting and classification purposes. This number will help you check the authenticity of the document, along with its authenticity marks. These will be holograms, codes, or stamps, authenticating your document.\n",
                },
                {
                    Question: "Is GIA reputable?",
                    Answer:
                        "Yes, in fact, GIA (Gemological Institute of America) is the most well-respected and reputable diamond grading entity in the diamond industry. They are consistent with each and every diamond report they assess and provide customers with the peace-of-mind they are looking for when purchasing any diamond. \n" +
                        "\n" +
                        "The fact that GIA has no financial stake in the sale of any diamond proves that their diamond evaluations are reputable. They grade all diamonds on a variety of qualities and elements, including:\n" +
                        "Diamond’s Evaluation Date \n" +
                        "Measurements (in millimeters)\n" +
                        "Cutting style and diamond shape\n" +
                        "Carat weight\n" +
                        "Table %\n" +
                        "Depth %\n" +
                        "Culet size\n" +
                        "Symmetry\n" +
                        "Proportion diagram\n" +
                        "Polish grade\n" +
                        "Girdle thickness\n" +
                        "Cut grade (Excellent, Very Good, Good, Poor)\n" +
                        "Color grade (ranging from D to J)\n" +
                        "Clarity grade (IF, VVS1, VVS2, VS1, VS2, SI1, SI2)\n" +
                        "Diamond plot showing all blemishes and inclusions\n" +
                        "Fluorescence grade\n" +
                        "Laser inscription (if applicable)\n" +
                        "Security features\n" +
                        "General comments\n" +
                        "\n" +
                        "GIA also has a reputation for rating Color and Clarity, the most subjective scales, more precisely. Because of GIA’s leading reputation, proven consistency, and history, we recommend only buying diamonds with a GIA report.\n",
                },
                {
                    Question: "Do all GIA diamonds have inscriptions?",
                    Answer:
                        "No, not all diamonds have laser inscriptions. Although it is quite common, today, there still are diamonds that do not have inscriptions. But having a laser inscribed in your diamond is, perhaps, more critical for your diamond than you might think. These engravings in the stone can help you, not only when deciding whether to buy it or not, but also protect you from fraud or negligence in other situations. The GIA laser-inscribed number enables you to match the diamond to its certification, providing you with all the essential information about that particular diamond. \n" +
                        "\n" +
                        "If you are not sure if your diamond has as inscription, the best way to check is to look at its GIA report. If you don’t see anyting regarding an inscription on the GIA report, then the diamond doesn’t have one. If your diamond does have an inscription, you can locate it on the girdle of the diamond with the help of your local jeweler.\n",
                },
                {
                    Question: "Does GIA certification matter?",
                    Answer:
                        "Yes, a GIA certification IS extremely essential because GIA (Gemological Institute of America) is the leader in the diamond grading industry. The GIA was the institute that devised the 4C’s (color, cut, clarity, and carat) of a diamond’s grading system that everyone now adheres to. In fact, they wrote the book that everyone uses to Grade, Buy, Sell, Price, and Compare Diamonds. \n" +
                        "\n" +
                        "There are, indeed, other diamond certifications that also grade and report on a diamond’s qualities, but they tend to be less accurate and consistent. A GIA Certification (aka, GIA Diamond Report) is the most accurate report your diamond can receive worldwide.\n",
                },
                {
                    Question: "Can I send my diamond GIA?",
                    Answer:
                        "Yes, in order to get a diamond certified or graded, anyone can submit a diamond to a GIA lab, worldwide. There are two GIA labs in the United States, located in New York City and Carlsbad. Having a diamond graded by GIA is a simple process that requires shipping the diamond to one of their labs. However, due to potential issues involved in shipping, insuring, and packaging diamonds, it is highly recommended to have a local jeweler submit the diamond on your behalf.\n" +
                        "\n" +
                        "It is also important to know that GIA only assesses and provides grading reports for dismounted diamonds. If you have a mounted diamond, a jeweler will have to remove your diamond from its setting before sending it into the GIA lab. This is another reason why it’s recommended to have your local jeweler submit the diamond for you. He/she can unmount and send the diamond into the lab, making the process much easier for you.\n",
                },
                {
                    Question: "Does GIA certification matter?",
                    Answer:
                        "Yes, to get a diamond certified or graded, anyone can submit a diamond to a GIA lab, worldwide. There are two GIA labs in the United States, located in New York City and Carlsbad. Having a diamond graded by GIA is a simple process that requires shipping the diamond to one of their labs. However, due to potential issues involved in shipping, insuring, and packaging diamonds, it is highly recommended to have a local jeweler submit the diamond on your behalf. \n" +
                        "\n" +
                        "It is also important to know that GIA only assesses and provides grading reports for dismounted diamonds. If you have a mounted diamond, a jeweler will have to remove your diamond from its setting before sending it into the GIA lab. This is another reason why it’s recommended to have your local jewelry store submit the diamond for you. The jeweler can unmount and send the diamond into the lab, making the process much easier for you.\n",
                },
                {
                    Question: "How much does a GIA report cost?",
                    Answer:
                        "Diamonds that are purchased from a local jewelry store usually include the GIA grading report, along with the overall cost of the diamond. But if you need to acquire a GIA certification for a diamond, itself, the process isn’t too expensive. It can range anywhere from $48, all the way up to $3,000, depending on the carat weight of the diamond. \n" +
                        "\n" +
                        "A GIA diamond grading report for a diamond weighing less than 1 carat (0.70 carats - 0.99 carats) costs $78, whereas a diamond weighing the standard weight of 1 carat costs $110. For diamonds weighing 2 carats or more, you’ll be looking at the cost of $180 and up. Considering that the GIA diamond grading report provides you with all the details and information about a diamond, it’s a small price to pay for an insurance of a diamond. \n" +
                        "\n" +
                        '<a href="https://www.gia.edu/gem-lab-fee-schedule">https://www.gia.edu/gem-lab-fee-schedule</a> \n',
                },
                {
                    Question: "Are GIA certified diamonds more expensive?",
                    Answer:
                        "No, GIA certified diamonds are not necessarily more expensive than other diamonds. But when it comes to pricing a diamond, it’s important to remember that a GIA-certified diamond’s quality has been 100% authenticated, making its value indisputable. And because GIA does not assign a monetary value to the graded diamonds, purchasing a GIA diamond places a higher value on adding in the peace-of-mind of the diamond - which is worth an added price for an educated diamond buyer. \n" +
                        "\n" +
                        "Also considering that many non-certified diamonds may also include questionable inclusions, non-natural or imitation stones, the addition of a GIA-certified diamond can be priceless.\n",
                },
                {
                    Question: "Does GIA grade mounted diamonds?",
                    Answer:
                        "No, GIA does not grade mounted diamonds. GIA only grades loose diamonds, because a mounting or setting has the possibility of obscuring or influencing the color and clarity grade of the diamond. It also makes it difficult to determine the diamond’s exact carat weight. Grading loose stones also allows GIA to detect fake diamonds or any treatments applied to the diamond without damaging the stone in any way. \n" +
                        "\n" +
                        "It is highly recommended to have a local jeweler remove the diamond from its setting before sending it to the GIA lab. Your local jeweler can also submit the diamond to a GIA lab for you after removing it from its setting, making the process much easier for you.\n",
                },
                {
                    Question: "Does GIA certify enhanced diamonds?",
                    Answer:
                        "Yes and no. When the GIA (Gemological Institute of America) receives a diamond for certification/grading, they carefully examine it for any treatments or enhancements that it may have undergone. These can include color coating, HPHT (high-pressure, high temperature), laser drilling, and fracture filling. These are all the processes that are designed to enhance the color or clarity of a diamond.\n" +
                        "\n" +
                        "There are specific treatments that prevent a diamond from receiving a GIA report. If the GIA verifies that a diamond has received a temporary treatment, it will not issue a grading report for that particular diamond. Some examples of these types of treatments include a coating or fracture filling, as the effects can accidentally be removed during cleaning.\n" +
                        "\n" +
                        "But, not all treatments are automatically excluded from a diamond from being graded. The GIA will, in fact, complete a report for any loose diamond that has undergone HPHT treatment or laser drilling. If GIA determines that a diamond has had these treatments, they will noticeably include it in the final report, making sure that future buyers and jewelers are fully aware of the treatment it contains. The GIA will even go as far as inscribing diamonds that have been HPHT processed to alert potential buyers of the treatment.\n",
                },
                {
                    Question: "What is a GIA diamond?",
                    Answer:
                        "GIA diamond is a term you will come across when shopping for engagement rings - short for a diamond rated by GIA, the maker of the 4Cs, and the independent diamond authority. Learn about GIA diamonds and why savvy shoppers trust GIA.\n" +
                        'As mentioned above, a GIA diamond is basically a shorthand that diamond sellers use to describe diamonds rated by GIA. The Diamond Institute of America (GIA) is an independent, non-profit organization that conducts gem research, trains gem specialists, and sets the standards for determining diamond quality. One of its primary functions is sorting diamonds. You may have heard or learned about the diamond grade 4Cs: color, clarity, cut, and carat weight. GIA has developed this standard, along with the scientific procedures for evaluating each "C" and the classification terminology used to describe them. \n' +
                        "\n" +
                        "GIA neither mines diamonds nor trades nor buys commercial diamonds. It does not value diamonds and is not otherwise involved in the pricing or valuation of diamonds. GIA operates independently of these commercial interests and ensures that diamond quality assessments are entirely objective and unbiased. \n" +
                        "If you want to know the quality of the diamond you are considering, and because quality and price go hand in hand, you should look for GIA-rated diamonds, and the corresponding GIA reports to assess diamonds. \n",
                },
                {
                    Question: "What is the history of the Gemological Institute of America?",
                    Answer:
                        "GIA has over 80 years of history and a track record of discovering the highest-quality diamonds. In the 1920s, the jewelry industry was undeveloped. New gems, synthetic gems, and cultured pearls were discovered and manufactured one after another, causing gem discrimination and unreliable grading.\n" +
                        "\n" +
                        "At that time, most jewelers had to rely solely on their intuition and experience., Robert M. Shipley, an idealist retail jeweler, was determined to change the current situation. After traveling to Europe and completing the Great Britain National Association of Goldsmith's gemological course, Shipley returned to Los Angeles. His fellow jewelers attended his lectures at the University of Southern California, which led to establishing the GIA (Gemological Institute of America) in 1931. \n" +
                        "\n" +
                        "A young gemologist named Richard T. Liddicoatt also significantly impacted the gem industry alongside Shipley. By opening classes in New York and expanding to Los Angeles, Liddicoatt laid a solid foundation for GIA on both sides of the Americas. \n" +
                        "\n" +
                        'Shipley became a world-class gemologist and systematized the GIA diamond grading with the "4Cs", Cut, Clarity, Color, and Carat. \n' +
                        "\n" +
                        "With the achievements of the grading foundation, jewelers no longer had to rely on vague intuition. \n" +
                        "\n" +
                        "GIA relocated its campus to Santa Monica in 1976 and, due to the expansion of education and operations in 1996, moved to a new school building on the west coast of Carlsbad's vast grounds. \n" +
                        "\n" +
                        "Today, Shipley's findings and systems are accepted worldwide, from New York to Tokyo and Osaka, Seoul, Taipei, Bangkok, Hong Kong, Moscow, London, and Mumbai. GIA continues to be a leader in diamond education and research around the globe. \n" +
                        "\n" +
                        "1931 - Robert M. Shipley Establishes GIA \n " +
                        "1934 - First issue of the magazine Gems & Gemology \n " +
                        "1939 - GG (Graduate Gemologist) course opened \n " +
                        '1940 - Richard T. Liddicoatt conceives diamond grading system "4C\'s."  \n ' +
                        '1947 - Published "Handbook of Gem Identification" (written by Redicoat)  \n ' +
                        "1952 - Mr. Liddicoatt appointed as the second president of GIA \n " +
                        "1953 - Established 4C diamond grading system \n " +
                        "1976 - Moved to Santa Monica \n " +
                        "1982 - GIA 50th Anniversary Symposium \n " +
                        "1983 - Glenn Nord becomes the third president \n " +
                        "1986 - William E. (Bill) Boyajian appointed as 4th President \n " +
                        "1996 - Moved from Santa Monica to Carlsbad for business expansion \n " +
                        "1999 - GIA 3rd International Gemological Symposium Held \n " +
                        "2006 - 5th President Donna M. Baker takes office \n ",
                },
                {
                    Question: "Where are the GIA Campuses and Locations Around the Globe?",
                    Answer:
                        "GIA has 14 campuses in 10 countries worldwide \n" +
                        "\n" +
                        "GIA Japan - Tokyo \n" +
                        "Yamaguchi Building 7, 11th Floor \n" +
                        "19-9, Taito 4 Chome \n" +
                        "Taito-ku, Tokyo, 110-0016 \n" +
                        "Tel: 81-3-5812-3215 \n" +
                        "\n" +
                        "GIA Japan - Osaka \n" +
                        "3F Nagahori Community Building \n" +
                        "2-5-8 Minamisenba, Chuo-ku \n" +
                        "Osaka, Japan 542-0081 \n" +
                        "Tel: 81-6-6266-8530 \n" +
                        "\n" +
                        "The Robert Mouawad Campus \n" +
                        "5345 Armada Drive \n" +
                        "Carlsbad, California 92008 \n" +
                        "Tel: 1-760-603-4000 \n" +
                        "\n" +
                        "GIA New York \n" +
                        "New York Education Center \n" +
                        "270 Madison Ave., 2nd Floor \n" +
                        "New York, New York 10016-0601 \n" +
                        "Tel: 1-212-944-5900 \n" +
                        "\n" +
                        "GIA Hong Kong \n" +
                        "Rm 301,3/F, Aon Chine Building, \n" +
                        "29 Queen's Road Central, \n" +
                        "Hong Kong \n" +
                        "Tel: 852-2303-0075" +
                        "\n" +
                        "GIA China - Shanghai \n" +
                        "Block J-L, 21st Floor, Shentong Information Plaza, \n" +
                        "Shanghai Jiao Tong University (SJTU) \n" +
                        "Shanghai \n" +
                        "Tel: 886-22771-9391 \n" +
                        "\n" +
                        "GIA China - Shenzhen \n" +
                        "Peking University Resource College \n" +
                        "Shenzhen \n" +
                        "Tel: 886-22771-9391 \n" +
                        "\n" +
                        "GIA India \n" +
                        "10th Floor Trade Centre \n" +
                        "Bandra Kurla Complex \n" +
                        "Bandra(East) \n" +
                        "Mumbai 400 051 India \n" +
                        "Tel: 91-2240851500 \n" +
                        "\n" +
                        "GIA Korea \n" +
                        "Keuk Dong Bldg. 5th Floor \n" +
                        "Kangnamku Sinsadong 639-3 \n" +
                        "Seoul, Korea \n" +
                        "Tel: 822-540-7637 \n" +
                        "\n" +
                        "GIA London \n" +
                        "104 Great Russell Street \n" +
                        "London, U.K. \n" +
                        "WC1B 3LA \n" +
                        "Tel: 44-20-7813-4321 \n" +
                        "\n" +
                        "GIA Moscow \n" +
                        "Miklukho-Maklaya Street, 23 \n" +
                        "117873 Moscow, Russia \n" +
                        "Tel: 7-095-433-5566 \n" +
                        "\n" +
                        "GIA Taiwan \n" +
                        "3F, No.270, Nanjing E. Rd., Sec.3, \n" +
                        "Taipei 105, \n" +
                        "Taiwan, ROC. \n" +
                        "Tel: 886-2-2771-9391 \n" +
                        "\n" +
                        "GIA Thailand \n" +
                        "U-Chu-Liang Building, 2nd Floor \n" +
                        "968 Rama 4 Road Silom, Bangrak \n" +
                        "Bangkok, 10500 Thailand \n" +
                        "Tel: 66-2632-4590 \n",
                },
                {
                    Question: "What are the most common GIA terms you should know?",
                    Answer: "Brilliant Cut Diamond, certificate, clarity scale diamond, diamond carat, diamond color grade, diamond cut, fluorescence, Gem Identification, Gemological Institute of America, gemologist, gg (Gemology master), gg program, gg qualification, GIA, GIA (Gemological Society of America), GIA appraiser, GIA cert, GIA diamond, GIA gg residence program, GIA japan, GIA japan gg program, GIA japan, jewelry school, GIA research, gia.edu, GIA-g.g The Gemological Society, Japan Jewelry Award, Jewel appraiser, Pearl grading.\n",
                },
                {
                    Question: "What is gemology?",
                    Answer: "In a nutshell, it's a discipline that studies gems. But <strong>GIA's</strong> GG program goes <strong>beyond</strong> just learning how to identify gemstones. Knowledge of color science, jewelry coordination, marketing, historical periods, and how they relate to jewelry, precious metals, and jewelry processing are essential to understanding jewelry and playing an active role in the jewelry industry. \n",
                },
                {
                    Question: "What is the GIA laboratory Division?",
                    Answer:
                        "GIA Laboratory, the laboratory division of GIA, is a leader in diamond grading, gem identification, and gem material radiation. \n " +
                        "The institute investigates and researches most of the world's most important stones, new imitations, and synthetic jewelry. \n" +
                        "The grading and identification technology developed by GIA Labs is essential for the industry and consumers, and important discoveries are incorporated frequently into GIA course materials. \n" +
                        "GIA Labs also contributes to the international jewelry community through laboratories such as New York and Carlsbad.\n",
                },
                {
                    Question: "How does GIA ensure objectivity in diamond sorting?",
                    Answer:
                        "GIA has robust procedures to ensure the anonymity of a diamond and the objectivity of the grading process. Upon arrival at the lab, the diamond is placed in a transparent anonymous storage box with no owner reference. It is assigned a barcode label that is used to track it throughout the process. All of these precautions ensure that a GIA assessment report objectively reflects the characteristics of a diamond. \n " +
                        "\n" +
                        "GIA diamond graders accurately assess the color, clarity, cut, and carat weight of the diamond. More experienced personnel gemologists can review some or all of the review information and provide independent opinions. A grade is only awarded when sufficient consensus has been reached. \n",
                },
                {
                    Question: "Do I really need to have a diamond assessed?",
                    Answer:
                        "Buying a diamond engagement ring is one of the most important purchases you can make. It can also be one of the most expensive. Therefore, you want to be sure that you are getting a reasonable price for the diamond you consider. \n " +
                        "\n" +
                        "A GIA diamond grading report includes all of the important information regarding a diamond's 4Cs and its fluorescence, any treatments, and more. Diamonds graded by the Gemological Institute of America (GIA) are guaranteed natural. That's because GIA has researched and tested the diamond using the most advanced tools and procedures available, based on the Institute's ongoing scientific research. \n" +
                        "\n" +
                        "A GIA diamond grading report provides an impartial assessment of the stone's quality characteristics, allowing users to compare one diamond with another. This will enable you to compare the quality of loose diamonds and decide which one is the best value and suitable for you. \n" +
                        "\n" +
                        "Ensuring the diamond you are viewing comes with a GIA diamond grading report is like getting an independent inspection before buying a car or home. It's a smart step in your buying process - and many insurance companies require a GIA assessment report as the basis for ensuring your diamond. \n" +
                        "\n" +
                        "GIA rates all shapes of diamonds, and the square princess shape is one of the most popular diamonds for engagement rings. \n",
                },
                {
                    Question: 'How do you get a diamond "GIA certified"?',
                    Answer:
                        "GIA does not certify diamonds, it rates them, and anyone can submit a diamond to GIA for assessment. You can drop off a stone at one of GIA's nine labs, located in gem and jewelry capitals worldwide, or you can ship it to GIA. Given the complexity of packaging, shipping, and insuring diamonds, and because GIA only assesses unmounted gemstones, your best option is to partner with your local jeweler to supply the stone on your behalf. \n " +
                        "\n" +
                        "Many diamonds in stores and online already have a GIA rating report, which you can request before purchasing. Your diamond report should also be given to you once you have completed your purchase. \n",
                },
                {
                    Question: 'Are "GIA Certified" diamonds more expensive?',
                    Answer:
                        "Again, \"GIA Certified\" really is a misnomer for GIA diamonds. There is a false idea that GIA diamonds cost more, often based on comparing diamonds based on the price tag. The price of a diamond, car, or other valuable item is linked to quality. If you're not sure what quality you're getting, how do you know if you're overpaying or getting a good deal? Therefore, it is essential to compare prices for diamonds with the same 4C quality characteristics and whose qualities have been accurately and objectively assessed by the same independent laboratory as GIA. \n " +
                        "\n" +
                        "Having a diamond assessed by GIA is not expensive. GIA charges only $ 48 for a grading report for a 0.70 ct to 0.99 ct diamond, and only $ 80 for a 1.00 ct to 1.49 ct diamond (Most jewelers include the grading fee in the price of the diamond.) Since couples spent an average of $ 6,553 on an engagement ring in 2020, a GIA diamond rating report's cost is a small price for peace of mind. \n",
                },
                {
                    Question: "Where can I find a GIA diamond?",
                    Answer: "All master jewelers on Willyou.net offer diamonds with GIA rating reports. The Willyou.net store locator can help you find retailers near you that sell GIA diamonds or have GIA-trained staff. \n ",
                },
                {
                    Question: 'What does it mean for a diamond to be a "GIA Certified Diamond"?',
                    Answer:
                        'The term "GIA certified diamond" is a misnomer. GIA does not certify diamonds - it assesses them. The terms "GIA Certified" and "GIA Certificate" are commonly used in the jewelry industry to refer to diamonds that have undergone GIA\'s rigorous assessment process and the reports associated with them. \n ' +
                        'GIA itself does not use the word "certificate" to describe the document it issues. The GIA evaluates the diamond, assesses it against the four Cs, and describes it. However, the GIA does not certify its value. On the contrary, like other science labs, GIA issues a report on the results of the evaluation of a particular diamond and believes that "report" is the correct term to describe the classification results. \n' +
                        "\n" +
                        "A GIA diamond rating report is essential when buying a diamond. GIA offers reports in several formats, including digital versions, each containing the trusted and highly accurate classification information that diamond shoppers can rely on. \n",
                },
                {
                    Question: "Is every diamond a GIA diamond?",
                    Answer:
                        "No, not all diamonds are rated by GIA. Although it may seem like it, given the sheer amount of GIA-rated diamonds on the market. This book is a testament to the trust jewelers and their customers' place in GIA's accurate and independent review services.\n" +
                        "To make sure your diamond has been assessed by the GIA, you can request the GIA assessment report. A full GIA assessment of the diamond's characteristics is included in the GIA report, along with a unique number. This number enables one to reach GIA's online global report database, called the GIA Report Check, and view the diamond's details as well. \n" +
                        "Round diamonds are the most popular shape for engagement rings, and GIA reports will include a cut grade for round brilliant cut diamonds. \n",
                },
                {
                    Question: "What is the history of GIA Japan, Tokyo & Osaka?",
                    Answer:
                        "GIA Japan was the first country outside of the United States in which GIA offered educational courses, beginning more than 50 years ago. \n" +
                        "Since its inception, GIA Japan has graduated 8,600 students. Today there are more than 3,400 GGs in Japan and 565 members in the GIA Alumni Association there. The branch has 11 instructors who teach classes in gemology, jewelry design, pearl, and wax.\n" +
                        "\n" +
                        "1971 - Establishment of the Association of Japan Gem Trust as a GIA agency in Japan and construction of a laboratory \n" +
                        "1972 - 1st open course held (Lecturer is Mr. Glenn Nord) 2 classes of 30 people each renting a room of the watch wholesale association \n" +
                        "1973 - 1st Diploma (Certificate of Completion) Award Ceremony (at Los Angeles) \n" +
                        "1976 - Opening of Osaka branch \n" +
                        "1981 - Total number of participants 2312, including 500 graduates \n" +
                        "1985 - 15th Anniversary ceremony celebration (Hotel Okura) \n" +
                        '1993 - GIA JAPAN Renamed and AGT News renamed to "Gemologists\' News" \n' +
                        "1993 - The 1st GG Residence Program starts \n" +
                        "1994 - The 1st Jewelry Design Class starts \n" +
                        "1995 - Counter sketching course starts \n" +
                        "1996 - Website Launched \n" +
                        "1997 - The 1st Wax Modeling Class starts \n" +
                        "2000 - Pearl course starts \n" +
                        "2001 - Total number of participants: 7491, including graduates: 2697 \n" +
                        "2001 - Acquired legal personality and became a general incorporated association GIA JAPAN \n",
                },
                {
                    Question: "What is the GIA GG program?",
                    Answer:
                        '<a href="https://willyou.net/blog/diamond-certifications/diamond-professionals/gia-gg-program">GG - GRADUATE GEMOLOGIST PROGRAM by the GIA</a>\n' +
                        "One of the most prestigious qualifications in the industry, the GIA Graduate Gemologist program provides the comprehensive knowledge of diamonds and colored stones needed to succeed anywhere in the jewelry business. You will acquire both technical expertise and practical skills to evaluate gemstones with the 4Cs (Color, Transparency, Cut, Carat Weight), International Diamond Grading System, and Colored Stone Grading System. \n" +
                        "\n" +
                        "In the jewelry business, a GG (Graduate Gemologist), who has international value, is educated as a specialist in comprehensive gemology including gem knowledge, grading/identification technology, business know-how, and ethics related to gem trading. This title is given to those who have mastered all aspects of gemology. \n" +
                        "In addition, there is no national qualification for gemology in Japan and other countries at present, but instead, the qualification as an internationally recognized gemology specialist is GIA (GEMOLOGICAL INSTITUTE OF AMERICA) GG. By obtaining this GG Diploma (GIA Gemological Institute of America Certificate), you will be accepted as a professional in Japan and around the world. The world-class education, confidence, and pride you’ll possess will be a step up in your life. \n" +
                        '<a href="https://www.gia.edu/gem-education/program-graduate-gemologist">https://www.gia.edu/gem-education/program-graduate-gemologist</a> \n',
                },
                {
                    Question: "GIA Japan - Jewelry school and the study of jewelry appraisal",
                    Answer: "GIA Japan has produced many GG holders who have acquired jewelry appraisal and identification techniques, general knowledge useful for the jewelry business, and specialized jewelry design skills. These GG graduates have a high sense of purpose and will be responsible for the industry’s future. \n",
                },
            ],
        };
    },
};
</script>
